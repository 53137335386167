@import "classless";
@import "markdown";
@import "syntax";

/* Prevent header from taking _too much_ space */
nav h1 {
  margin: 0;
}

/* Vertically center nav text alongside larger H1 */
nav li>a {
  line-height: 2.5em;
}

/* Tighten up rendering of code listings */
pre > code {
  padding: 0;
}
code > table {
  margin: 0;
}
code table pre {
  margin: 0;
}

/* Get rid of paragraph numbering on article H2s */
article h2::before {
  content: "";
}

/* I dislike the right-align on non-first table column */
th, td {
  text-align: left;
}
